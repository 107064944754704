import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import '../plugins/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //ログイン成功時のトークン保存
    token: '',

    //メニューの表示・非表示を保存 true:表示
    showMenu: true,

    //会員ID
    memberId: '',

    //会員会社名
    corpName: '',

    //得意先
    tokuisakiList: [],

    //現場
    genbaList: [],

    //コンテナ
    containerList: [],

    //コンテナタイプ
    containerTypeList: [],

    //メンテナンスメッセージ
    mainteList: [],

    //インフォメーション未読数
    infoCount: 0,

    //得意先Cd(画面遷移用)
    tokuiCd: '',

    //得意先名(画面遷移用)
    tokuiName: '',

    //現場CD(画面遷移用)
    genbaCd: '',

    //貸出No(画面遷移用)
    rentNo: '',
    rentSortKey: '',
    rentIsDesc: false,
    rentPage: 1,

    //貸出予約No(画面遷移用)
    reserveNo: '',
    reservePage: 1,

    //QRコード印刷注文
    printMode: '',
    printEditNo: '',
    printList: [],

    // マップ用貸出情報一覧
    mapContList: [],
    mapDispList: [],
  },
  getters: {
    getMapContList: state => {
      return state.mapContList;
    },
    getMapDispList: state => {
      return state.mapDispList;
    }
  },
  mutations: {
    //メニューの表示・非表示を切り替える
    chgShowMenu(state) {
      state.showMenu = !state.showMenu;
    },
    setShowMenu(state, payload) {
      state.showMenu = payload.flg;
    },
    setToken(state, token) {
      state.token = token;
    },

    //会員ID
    setMemberId(state, memberId) {
      state.memberId = memberId;
    },
    setCorpName(state, corpName) {
      state.corpName = corpName;
    },

    setTokuisakiList(state, tokuisakiList) {
      state.tokuisakiList = tokuisakiList;
    },
    setGenbaList(state, genbaList) {
      state.genbaList = genbaList;
    },
    setContainerList(state, containerList) {
      state.containerList = containerList;
    },
    setContainerTypeList(state, containerTypeList) {
      state.containerTypeList = containerTypeList;
    },
    setMainteList(state, mainteList) {
      state.mainteList = mainteList;
    },
    setInfoCount(state, cnt) {
      state.infoCount = cnt;
    },
    setTokuiCd(state, tokuiCd) {
      state.tokuiCd = tokuiCd;
    },
    setTokuiName(state, tokuiName) {
      state.tokuiName = tokuiName;
    },
    setGenbaCd(state, genbaCd) {
      state.genbaCd = genbaCd;
    },
    setRentNo(state, rentNo) {
      state.rentNo = rentNo;
    },
    setRentSortKey(state, rentSortKey) {
      state.rentSortKey = rentSortKey;
    },
    setRentIsDesc(state, rentIsDesc) {
      state.rentIsDesc = rentIsDesc;
    },
    setRentPage(state, rentPage) {
      state.rentPage = rentPage;
    },
    setReserveNo(state, reserveNo) {
      state.reserveNo = reserveNo;
    },
    setReservePage(state, reservePage) {
      state.reservePage = reservePage;
    },
    setPrintMode(state, printMode) {
      state.printMode = printMode;
    },
    setPrintEditNo(state, printEditNo) {
      state.printEditNo = printEditNo;
    },
    setPrintList(state, printList) {
      state.printList = printList;
    },
    setMapContList(state, mapContList) {
      state.mapContList = mapContList;
    },
    setMapDispList(state, mapDispList) {
      state.mapDispList = mapDispList;
    }
  },
  actions: {
    getMapContainer(context) {
      // const config = {
      //   baseURL: 'https://test-api.web-container.net/',
      //   timeout: 60 * 1000,
      //   headers: {
      //     'Content-Type': 'application/json; charset=utf-8',
      //     'X-Requested-With': 'XMLHttpRequest',
      //     //'Authorization':'Bearer '  + mapState['token']
      //   }
      // };
      // if(document.domain === 'member.web-container.net'){
      //   config.baseURL = 'https://api.web-container.net/';
      // }
      // const _axios = axios.create(config);

      // //リクエスト時の共通化
      // _axios.interceptors.request.use(
      //   config => {
      //     config.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      //     return config;
      //   }
      // )

      axios.post('/api/rent-map-search', {
        tokuiCd: '',
        contCd: '',
        genbaCd: '',
        status: [1, 2, 3],
      }).then((res) => {
        if (res.data.result == 0) {
          context.commit('setMapContList', res.data.rentList);
        }
      })
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: 'vuex-session',
      storage: window.sessionStorage
    })
  ]
})
