<template>
  <v-card class="menu mx-auto">
    <v-navigation-drawer
      app
      v-model="isMenu"
      clipped
      color="blue lighten-5"
      v-if="isLogin"
    >
      <v-container class="pl-0 pr-0">
        <v-list dense nav>

          <v-list-item-group v-model="selectedMenu" color="transparent">
            <v-list-item
              to="/"
            >
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ダッシュボード
                  <v-badge class="ml-3" :value="infoCount>0?'true':''" color="green" :content="infoCount"></v-badge>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
            <v-toolbar-title class="text-subtitle-1"
              >コンテナ管理</v-toolbar-title
            >
          </v-toolbar>


            <v-list-item
              v-for="nav_list in nav_lists"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="nav_list.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >得意先・担当管理</v-toolbar-title
              >
            </v-toolbar>

            <v-list-item
              v-for="nav_list in nav_tokui"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="nav_list.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >マスタ管理</v-toolbar-title
              >
            </v-toolbar>

            <v-list-item
              v-for="nav_list in nav_mente"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense v-if="true">
              <v-toolbar-title class="text-subtitle-1"
                >その他</v-toolbar-title
              >
            </v-toolbar>

            <v-list-item v-if="true"
              v-for="nav_list in nav_other"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >QRコード印刷</v-toolbar-title
              >
            </v-toolbar>

            <v-list-item
              v-for="nav_list in nav_print"
              :key="nav_list.no"
              :to="nav_list.link"
            >
            <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >帳簿</v-toolbar-title
              >
            </v-toolbar>

            <v-list-item
              v-for="nav_list in nav_chobo"
              :key="nav_list.no"
              :to="nav_list.link"
            >
            <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </v-card>
</template>
<style scope>
.menu .v-item--active {
    background: #f2ce32;
}
.menu .v-list-item__icon,
.menu .v-list-item__content {
    color: black;
}
.menu-title {
    background-color: red im !important;
}
</style>
<script>
export default {
  data: () => ({
    drawer: null,
    selectedMenu: 1,
    nav_lists: [
      {
        name: "貸出データ管理",
        icon: "mdi-truck",
        link: "/rent",
        no: 1,
      },
      {
        name: "貸出マップ",
        icon: "mdi-google-maps",
        link: "/map",
        no: 2,
      },
      {
        name: "貸出予約",
        icon: "mdi-calendar-range",
        link: "/reserve",
        no: 112,
      },
    ],

    nav_tokui: [
      {
        name: "得意先・現場管理",
        icon: "mdi-account-multiple",
        link: "/tokui",
        no: 3,
      },
      { name: "自社担当者管理", icon: "mdi-account", link: "/tanto", no: 4 },
    ],
    nav_mente: [
      {
        name: "種類マスタ",
        icon: "mdi-database",
        link: "/syuruimst",
        no: 6,
      },
      {
        name: "コンテナマスタ",
        icon: "mdi-database",
        link: "/contmst",
        no: 7,
      },
      {
        name: "マスタ一括取込機能",
        icon: "mdi-database",
        link: "/importmaster",
        no: 10,
      },
      {
        name: "拠点マスタ",
        icon: "mdi-database",
        link: "/kyotenmst",
        no: 30,
      },
    ],
    nav_other: [
      {
        name: "お問い合わせ",
        icon: "mdi-email",
        link: "/Inquiries",
        no: 40,
      },
      {
        name: "アラート設定",
        icon: "mdi-database",
        link: "/alertlist",
        no: 41,
      }
    ],
    nav_print: [
      {
        name: "QRコード印刷注文",
        icon: "mdi-qrcode",
        link: "/printorder",
        no: 8,
      },
      {
        name: "QRコード印刷注文一覧",
        icon: "mdi-qrcode",
        link: "/printlist",
        no: 9,
      },
    ],
    nav_chobo: [
      {
        name: "得意先別ｺﾝﾃﾅ種類別集計",
        icon: "mdi-format-list-bulleted",
        link: "/tokuisaki_syuruibetsu",
        no: 120,
      },
      {
        name: "現場別ｺﾝﾃﾅ種類別集計",
        icon: "mdi-format-list-bulleted",
        link: "/genba_syuruibetsu",
        no: 130,
      },
      {
        name: "コンテナ種類別集計",
        icon: "mdi-format-list-bulleted",
        link: "/syuruibetsu",
        no: 140,
      },
      {
        name: "拠点在庫集計",
        icon: "mdi-format-list-bulleted",
        link: "/zaikosyuukei",
        no: 150,
      },
    ],
  }),
  computed: {
    infoCount(){
      return this.$store.state.infoCount;

    },
    isLogin() {
      return this.$store.state.token == "" ? false : true;
    },
    isMenu: {
      get(){
        return this.$store.state.showMenu;
      },
      set(value){
          this.$store.commit('setShowMenu',{flg:value})
      }
    },
  },
  methods: {
  },
};
</script>
