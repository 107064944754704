import Vue from 'vue';
import axios from 'axios';

import store from '../store'
import { router } from '../router'
var config = {
  baseURL: 'https://dev-api.web-container.net/',
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
    //'Authorization':'Bearer '  + mapState['token']
  }
};
if (document.domain === 'test-member.web-container.net') {
  config.baseURL = 'https://test-api.web-container.net/';
}
if (document.domain === 'test-daiwa-member.web-container.net') {
  config.baseURL = 'https://test-daiwa-api.web-container.net/';
}
// if (document.domain === 'test-daiwa-member.web-container.net') {
//   config.baseURL = 'https://test-api.web-container.net/';
// }
if (document.domain === 'member.web-container.net') {
  config.baseURL = 'https://api.web-container.net/';
}
if (document.domain === 'dev-member.web-container.net') {
  config.baseURL = 'https://dev-api.web-container.net/';
}
var self = this;
const _axios = axios.create(config);

//リクエスト時の共通化
_axios.interceptors.request.use(
  config => {
    config.headers.common['Authorization'] = 'Bearer ' + store.state.token;
    return config;
  }
)
//レスポンスの共通化
_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {

    if (!error.response) return Promise.reject(error);

    if (error.response.status === 401) {
      /**
       * トークンが期限切れの場合 401が発生する
       * エラー画面にリダイレクト
       */
      store.commit('setToken', '');
      router.push({ name: "Timeout" });
    } else if (error.response.status === 503) {
      /**
       * メンテナンス時 503が発生する
       * メンテナンス画面にリダイレクト
       */
      store.commit('setToken', '');
      store.commit('setMainteList', error.response.data);
      router.push({ name: "Maintenance" }).catch(err => { });
    }
    return Promise.reject(error);
  }
)

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin