<template>
    <v-footer class="footer-title" height="50" dark app v-if="isLogin" >
      <v-card-text class="text-center">
      産廃コンテナBee システム © 2022 SMS Corp.
      </v-card-text>
    </v-footer>
</template>
<style lang="scss" scoped>
.footer-title{
  background: #eee !important;
  color: #555 !important;
}
</style>
<script>

export default {
    computed:{
        isLogin(){
            return this.$store.state.token==''?false:true;
        }
    }
}
</script>
