<template>
  <v-container fluid class="mt-0 pa-0">
    <v-sheet elevation="2" height="40" class="content-icon pt-2 pl-3 ml-0 mb-4">
      <v-icon class="mb-2">mdi-home</v-icon>
      ダッシュボード
    </v-sheet>
    <v-row class="ml-5 my-8">
      <v-col cols="8">
        <div class="dash-parts">
          <div class="pa-1 rounded-t-lg title">
            <v-icon class="mb-1"> mdi-checkbox-marked</v-icon>
            お知らせ
          </div>
          <div class="info-content">
            <p class="pa-2" style="font-size:30px;" v-if="news.length == 0">現在お知らせはありません。</p>
            <div v-for="item in news" :key="item.id">
              <p class="mt-3 mb-0 text-caption font-weight-bold">
                {{ dateFormat(item.disp_date, "YYYY年MM月DD日") }}
              </p>
              <p class="news text-subtitle-1 mb-0 indigo--text text--darken-4" @click="openDetail(item)">
                <v-badge dot left inline :value="item.readflg == 1 ? '' : 'ON'" color="red">{{ item.title }}</v-badge>
              </p>
              <v-divider class="py-0 mr-8" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="ml-5 my-8">
      <v-col cols="8">
        <div class="dash-parts">
          <div class="pa-1 rounded-t-lg title">
            <v-icon class="mb-1"> mdi-checkbox-marked</v-icon>
            資料ダウンロード・リンク 
          </div>
          <div class="info-content">
            <siryo-download></siryo-download>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>

</template>

<style lang="scss" scoped>
//------ダッシュボード画面
// タイトルカラー
$dash-parts-title-color: #01579b;
$dash-parts-cont-color: #004d40;

.dash-parts {
  .title {
    background-color: $dash-parts-title-color  !important;
    font-size: 17px !important;
    color: white !important;
    width: 100% !important;
    padding-left: 10px !important;

    .v-icon {
      color: #b2dfdb !important;
    }
  }

  .content {
    &-lg {
      font-size: 28px !important;
      font-weight: 800;

      .v-icon {
        font-size: 32px !important;
      }
    }
  }
}

.info-content {
  border: 1px solid #ccc;
  padding-left: 20px;
}

.page-title {
  background-color: cadetblue;
  margin-bottom: 10px;
  padding: 0;
}

.news {
  transition-duration: 0.3s;
}

.news:hover {
  opacity: 0.6;
  /* background-color: #f0f0f0; */
  cursor: pointer;
}
</style>

<script>
import SiryoDownload from "../components/SiryoDownload.vue";
export default {
  name: "Dashboard",
  components: { SiryoDownload },
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    this.axios.get("/api/info/list").then((res) => {
      this.news = this.infoReadCheck(res.data.information);
    });
  },
  methods: {
    openDetail(item) {
      this.infoRead(item.id);
      this.$router.push({ name: "News", params: { infoId: item.id } });
    },
  },
};
</script>
