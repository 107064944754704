import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import HeaderBar from '@/components/Header'
//import axios from 'axios'
//import VueAxios from 'vue-axios'
//import VueSwal from 'vue-swal'
//import Swal from 'sweetalert2'
import './plugins/api'
//import VuetifyConfirm from 'vuetify-confirm'
import helpers from './helpers'
import 'vuetify/src/styles/main.sass'

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCookies from 'vue-cookies';


Vue.use(VueSweetalert2);
require('./assets/scss/style.scss')
Vue.component(HeaderBar);
Vue.config.productionTip = false
Vue.mixin(helpers)
//Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VueCookies, { expire: '365d' });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
