import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Dashboard'
//store
import Store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    /**
     * レンタル一覧
     */
    path: '/rent',
    name: 'Rent',
    component: () => import('../views/Rent.vue')
  },
  {
    /**
     * レンタル更新 
     */
    path: '/rentedit',
    name: 'RentEdit',
    component: () => import('../views/RentEdit.vue')
  },
  {
    /**
     * 貸出マップ
     */
    path: '/map',
    name: 'Map',
    component: () => import('../views/ContMap.vue')
  },
  {
    /**
     * 貸出予約
     */
    path: '/reserve',
    name: 'Reserve',
    component: () => import('../views/Reserve.vue')
  },
  {
    /**
     * 貸出予約更新
     */
    path: '/reserveedit',
    name: 'ReserveEdit',
    component: () => import('../views/ReserveEdit.vue')
  },
  {
    /**
     * 得意先・現場 
     */
    path: '/tokui',
    name: 'Tokui',
    component: () => import('../views/Tokuisaki.vue')
  },
  {
    /**
     * 得意先編集
     */
    path: '/edit_tokui',
    name: 'EditTokui',
    component: () => import('../views/TokuisakiEdit.vue')
  },
  {
    /**
     * 現場編集 
     */
    path: '/edit_genba',
    name: 'EditGenba',
    component: () => import('../views/GenbaEdit.vue')
  },
  {
    /**
     * 担当者 
     */
    path: '/tanto',
    name: 'Tanto',
    component: () => import('../views/Tanto.vue')
  },
  {
    /**
     * 担当者編集 
     */
    path: '/tanto_edit',
    name: 'TantoEdit',
    component: () => import('../views/TantoEdit.vue')
  },
  {
    /**
     * 作業担当者(現場) 
     */
    path: '/sagyosha',
    name: 'Sagyosha',
    component: () => import('../views/Sagyosha.vue')
  },
  {
    /**
     * コンテナマスタ 
     */
    path: '/contmst',
    name: 'Contmst',
    component: () => import('../views/ContMaster.vue')
  },
  {
    /**
     * 拠点マスタ 
     */
    path: '/kyotenmst',
    name: 'Kyotenmst',
    component: () => import('../views/KyotenMaster.vue')
  },
  {
    /**
     * 種類マスタ 
     */
    path: '/syuruimst',
    name: 'Syuruimst',
    component: () => import('../views/SyuruiMaster.vue')
  },
  {
    /**
     * QRコード印刷注文画面
     */
    path: '/printorder',
    name: 'Printorder',
    component: () => import('../views/PrintOrder.vue')
  },
  {
    /**
     * QRコード印刷注文一覧
     */
    path: '/printlist',
    name: 'Printlist',
    component: () => import('../views/PrintList.vue')
  },
  {
    /**
     * マスター取込機能画面
     */
    path: '/importmaster',
    name: 'ImportMaster',
    component: () => import('../views/ImportMaster.vue')
  },
  {
    /**
     * 会員情報 
     */
    path: '/memberinfo',
    name: 'MemberInfo',
    component: () => import('../views/MemberInfo.vue')
  },
  {
    /**
     * お知らせ
     */
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/timeout',
    name: 'Timeout',
    component: () => import('../views/Timeout.vue')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/Maintenance.vue')
  },
  {
    /**
     *コンテナ種類別集計 
     */
    path: '/syuruibetsu',
    name: 'SyuruiBetsu',
    component: () => import('../views/SyuruiBetsu.vue')
  },
  {
    /**
     *得意先コンテナ種類別集計 
     */
    path: '/tokuisaki_syuruibetsu',
    name: 'TokuisakiSyuruiBetsu',
    component: () => import('../views/TokuisakiSyuruiBetsu.vue')
  },
  {
    /**
     *現場コンテナ種類別集計 
     */
    path: '/genba_syuruibetsu',
    name: 'GenbaSyuruiBetsu',
    component: () => import('../views/GenbaSyuruiBetsu.vue')
  },
  {
    /**
     *帳票 
     */
    path: '/zaikosyuukei',
    name: 'ZaikoSyuukei',
    component: () => import('../views/ZaikoSyuukei.vue')
  },
  {
    /**
     * お問合せ
     */
    path: '/inquiries',
    name: 'Inquiries',
    component: () => import('../views/Inquiries.vue')
  },
  {
    /**
     * お問合せtest
     */
    path: '/inquiriestest',
    name: 'Inquiriestest',
    component: () => import('../views/Inquiriestest.vue')
  },
  {
    /**
     * お知らせ
     */
    path: '/alertlist',
    name: 'AlertList',
    component: () => import('../views/AlertList.vue')
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {

  if (to.name === 'Login') {
    axios.get('/api/maintenance').then((res) => {
      if (res.data.result == 1) {
        Store.commit('setMainteList', res.data.data);
        next({ name: 'Maintenance' });
      } else {
        next()
      }
    });
  } else if (to.name === 'Maintenance') {
    axios.get('/api/maintenance').then((res) => {
      if (res.data.result == 0) {
        next({ name: 'Login' });
      } else {
        next()
      }
    });
  } else if (to.name === 'Timeout') {
    next()
  } else {
    if ((to.name !== 'Login') && Store.state.token == '') next({ name: 'Login' })
    else next()

  }
})

export default router
