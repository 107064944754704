<template>
    <v-app-bar class="header-title" dark app clipped-left v-if="isLogin">
      <v-app-bar-nav-icon @click="chgShowMenu"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-h4 web-container-title">
        <img src="../../public/img/sms-title.png" width="220" heght="27" />&nbsp;</v-toolbar-title>
      <div class="px-5 pt-3">{{corpName}}　<span style="font-size:80%">様</span></div> 
      <v-spacer></v-spacer>
      <v-btn outlined class="mr-3" @click="clickMember">会員情報</v-btn>
      <v-btn outlined @click="logout">ログアウト</v-btn>
    </v-app-bar>
</template>
<style lang="scss" scoped>
.header-title{
  background: #eee !important;
}
.header-title, .header-title .v-btn{
  color: #555 !important;
}
.web-container-title{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 800;
  color: #FF7C00;
  background: -webkit-linear-gradient(top, #fffefd 0%, #eff1f8 50%, #d1daf3 51%, #8893f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
<script>
import { MESSAGE } from "../messages";
export default {
  name: 'HeaderBar',
  data: () => ({
            drawer: null,
  }),
    computed:{
        isLogin(){
            return this.$store.state.token==''?false:true;
        },
        corpName(){
            return this.$store.state.corpName;
        } 
  },
  methods:{
      chgShowMenu(){
          this.$store.commit('chgShowMenu')
      },
      async logout(){

        const res = await this.swalConfirm(
            MESSAGE.TITLE_CONFIRM,
            MESSAGE.formatByArr(MESSAGE.R004, 'ログアウト'));

        if( !res ){
            return ;
        }

        axios.post('/api/logout');
        this.$store.commit('setToken' ,'');
        this.$router.push('/login')

      },
      clickMember() {
          this.$router.push('/memberinfo')
      
      }
  }
};
</script>
