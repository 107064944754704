import moment from "moment";
import store from "./store";

export default {
  methods: {
    /*****************************************
     * ステータス名称変換
     * @param {*} cd
     * @returns
     ****************************************/
    getStatusName(cd) {
      var statusName = "";
      switch (cd) {
        case 9:
          statusName = "回収済み";
          break;
        case 0:
          statusName = "未設置";
          break;

        case 1:
          statusName = "設置中";
          break;
        case 2:
          statusName = "交換依頼中";
          break;
        case 3:
          statusName = "撤去依頼中";
          break;
        default:
          break;
      }
      return statusName;
    },
    /*****************************************
     * 破棄区分名称変換
     * @param {*} cd
     ****************************************/
    getDisposStatus(dis, ren) {
      let val = "";
      if (dis == 1) {
        val = "廃棄";
      } else if (dis == 2) {
        val = "修理中";
      } else if (ren == 1) {
        val = "貸出中";
      }
      return val;
    },
    /*****************************************
     * 配列が存在するかを確認する
     * @param {エラー配列} val
     * @returns
     ****************************************/
    isError: function(val) {
      if (val == undefined) return false;
      if (!Array.isArray(val)) return false;
      if (val.length > 0) {
        return true;
      }
      return false;
    },
    /*****************************************
     * エラーメッセージ表示編集
     * コントロール下に表示するエラーメッセージのフォーマット
     ****************************************/
    dispError: function(val) {
      var err = "";
      for (var key in val) {
        err = err + `<p class="ma-0">${val[key]}</p>`;
      }
      return err;
    },
    /*****************************************
     * 郵便番号チェック
     * @param {郵便番号} str
     * @returns
     ****************************************/
    isPostCode: function(str) {
      if (str.match(/^\d{3}-?\d{4}$/)) {
        return true;
      }
      return false;
    },
    /*****************************************
     * メールアドレスチェック
     * @param {文字列}} ymd
     * @returns
     ****************************************/
    isMailaddress: function(str) {
      str = str == null ? "" : str;
      if (
        str.match(
          /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 電話番号チェック
     * @param {文字列}} ymd
     * @returns
     ****************************************/
    isTelNo: function(str) {
      str = str == null ? "" : str;
      let len = str.replace(/-/g, "").length;
      if (str.match(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{3,4})$/) && len >= 7) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 半角英数チェック
     * @param {文字列}} ymd
     * @returns
     ****************************************/
    isHanEisu: function(str) {
      str = str == null ? "" : str;
      if (str.match(/^[A-Za-z0-9]*$/)) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 半角英数チェック(記号有り)
     * @param {文字列}} ymd
     * @returns
     ****************************************/
    isHanEisuKigo: function(str) {
      str = str == null ? "" : str;
      if (str.match(/^[A-Za-z0-9!#&]*$/)) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 全角カタカナチェック
     * @param {文字列}}
     * @returns
     ****************************************/
    isZenKana: function(str) {
      str = str == null ? "" : str;
      if (str.match(/^[ァ-ロワヲンヴヵヶー]+$/)) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 日付のフォーマット
     * @param {日付} ymd
     * @returns
     ****************************************/
    checkSpace: function(str) {
      str = str == null ? "" : str;
      if (str.match(/\s+/)) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 記号チェック
     * @param {文字列}}
     * @returns
     ****************************************/
    checkSymbol: function(str) {
      str = str == null ? "" : str;
      if (str.match(/[!#<>:;&~@%+$=?"\'\*\^\(\)\[\]\|\/\.,_-]/g) ||
        str.match(/[！＃＜＞：；＆～＠％＋＄＝？”’＊＾（）［］｜／．，＿ー・]/g)) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 記号チェック
     * @param {文字列}}
     * @returns
     ****************************************/
    checkDakuten: function(str) {
      str = str == null ? "" : str;
      if (str.match(/[゛]/g)) {
        return true;
      } else {
        return false;
      }
    },
    /*****************************************
     * 日付のフォーマット
     * @param {日付} ymd
     * @returns
     ****************************************/
    dateFormat: function(ymd, fmt = "YYYY-MM-DD") {
      if (ymd == null || !moment(ymd).isValid) {
        return "";
      }

      const date = moment(ymd);
      return date.format(fmt);
    },
    /*****************************************
     * 年月日のフォーマットチェック
     * @param {年月日 or 時分} ymdhm
     * @returns
     ****************************************/
    checkYmdHm(ymdhm, fmt = "YYYY-MM-DD") {
      //空チェック
      if (!ymdhm || moment(ymdhm, fmt, true).isValid()) {
        return false;
      }
      return true;
    },
    /*****************************************
     * 日付のフォーマット
     * @param {日付} ymd
     * @returns
     ****************************************/
    daysFormat: function(days) {
      days = days === null || days === "" ? "" : days + "日";
      return days;
    },
    dateFromToCheck: function(from, to) {
      if (to === null || !moment(to).isValid) {
        return true;
      }
      if (from === null || !moment(from).isValid) {
        return true;
      }
      const toDate = moment(to);
      const fromDate = moment(from);
      if (fromDate > toDate) {
        return false;
      }
      return true;
    },
    /**
     * 数値カンマ編集
     */
    numberFormat: function(price) {
      return String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    /**
     * 先頭の０削除
     */
    zeroSuppress: function(cd) {
      cd = String(cd);
      let ptn = /^0+/;

      if (ptn.test(cd)) {
        return cd.replace(/^0+/, "");
      } else {
        return cd;
      }
    },
    /**
     * コンテナコードの編集
     */
    contCodeFormat: function(contId) {
      // var ptn = /^[0-9]+$/;
      // if (ptn.test(contId)) {
      //     return ('0000000' + contId).slice(-7);
      // } else {
      //     return contId;
      // }
      return contId;
    },
    /**
     * 担当者コードの編集
     */
    TantouCodeFormat: function(tantouCd) {
      var ptn = /^[0-9]+$/;
      if (ptn.test(tantouCd)) {
        return ("00000" + tantouCd).slice(-5);
      } else {
        return tantouCd;
      }
    },
    /**
     * 発注ステータス
     */
    hatchuStatus: function(sts) {
      let statusName = "";
      switch (sts) {
        case null:
          statusName = "未発注";
          break;
        case 1:
          statusName = "発注済";
          break;
        case 2:
          statusName = "印刷中";
          break;
        case 3:
          statusName = "発送済";
          break;
        default:
          statusName = "未発注";
          break;
      }
      return statusName;
    },
    /**
     * Confirm メッセージ表示
     * 「はい」「いいえ」ボタン
     * @param {タイトル} title
     * @param {メッセージ内容} message
     * @returns true:はい
     *          false:いいえ
     */
    swalConfirm: function(title, message) {
      setTimeout(function() {
        if (document.getElementsByClassName("v-dialog__content").length > 0) {
          document
            .getElementsByClassName("v-dialog__content")
            [
              document.getElementsByClassName("v-dialog__content").length - 1
            ].classList.add("swal-on-1");
        }
        if (document.getElementsByClassName("swal2-container").length > 0) {
          document
            .getElementsByClassName("swal2-container")[0]
            .classList.add("swal-on-2");
          document.activeElement.blur();
        }
      }, 50);
      setTimeout(function() {
        if (document.getElementsByClassName("swal2-container").length > 0) {
          document.getElementsByClassName("swal2-confirm")[0].focus();
        }
      }, 150);
      return this.$swal({
        title: title,
        html: message,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "はい",
        cancelButtonText: "いいえ",
      }).then((result) => {
        if (result.isConfirmed) {
          /**はい */
          return true;
        }
        /**いいえ */
        if (document.getElementsByClassName("swal-on-1").length > 0) {
          document.getElementsByClassName("swal-on-1")[0].focus();
        }
        return false;
      });
    },
    /**
     * アラートメッセージ表示
     * @param {*} title
     * @param {*} message
     * @param {*} icon
     * @returns
     */
    swalAlert: function(title, message, icon = "error") {
      setTimeout(function() {
        if (document.getElementsByClassName("v-dialog__content").length > 0) {
          document
            .getElementsByClassName("v-dialog__content")
            [
              document.getElementsByClassName("v-dialog__content").length - 1
            ].classList.add("swal-on-1");
        }
        if (document.getElementsByClassName("swal2-container").length > 0) {
          document
            .getElementsByClassName("swal2-container")[0]
            .classList.add("swal-on-2");
          document.activeElement.blur();
        }
      }, 50);
      setTimeout(function() {
        if (document.getElementsByClassName("swal2-container").length > 0) {
          document.getElementsByClassName("swal2-confirm")[0].focus();
        }
      }, 150);
      if (document.getElementsByClassName("swal-on-1").length > 0) {
        document.getElementsByClassName("swal-on-1")[0].focus();
      }
      this.$swal({
        title: title,
        text: message,
        icon: icon,
      });
      return;
    },
    /**
     * トーストメッセージ
     * @param {*} title
     * @param {*} icon
     */
    swalToast: function(title, icon = "success") {
      this.$swal({
        text: title,
        toast: true,
        icon: icon,
        showConfirmButton: false,
        position: "bottom-end",
        timer: 3000,
      });
    },
    /**
     *
     * @param {メッセージの内容} mes
     *     "これは{0}です{1}"
     * @param {埋め込む引数} param
     *     ["1111","2222"]
     * @returns
     */
    generateMessage: function(mes, param) {
      let result;
      for (i = 0; i < param.length; i++) {
        let index = "{" + i + "}";
        result = mes.replace(index, param[i]);
      }
      return result;
    },
    /*****************************************
     * 緯度・経度チェック
     * @param {緯度/経度} latlng
     * @returns
     ****************************************/
    latlngFormat: function(latlng) {
      latlng = typeof latlng == "string" ? latlng : String(latlng);

      if (latlng.match(/^[-]?[0-9\.]*$/) && latlng > -1000 && latlng < 1000) {
        return false;
      } else {
        return true;
      }
    },
    /*****************************************
     * マップ上のマーカーの色取得
     * @param {*} cd
     * @returns
     ****************************************/
    getMarkerColor(cd) {
      let color = "blue";
      switch (cd) {
        case 1:
          color = "blue";
          break;

        case 2:
          color = "purple";
          break;

        case 3:
          color = "red";
          break;

        default:
          break;
      }
      return color;
    },

    /**
     * 既読をチェックする
     * @param {お知らせの配列} info
     * @returns
     */
    infoReadCheck(info) {
      let reades = (this.$cookies.get("infoReads") ?? "").split(",");

      let arrInfo = [];
      let infoReads = [];
      let cnt = 0;
      let readflg = "0";
      for (let i in info) {
        if (reades.includes(String(info[i].id))) {
          readflg = "1";
          infoReads.push(info[i].id);
        } else {
          readflg = "0";
          cnt++;
        }
        arrInfo.push({
          id: info[i].id,
          disp_date: info[i].disp_date,
          title: info[i].title,
          readflg: readflg,
        });
      }
      this.$cookies.set("infoReads", infoReads, "5y");
      store.commit("setInfoCount", cnt);
      return arrInfo;
    },
    /**
     * お知らせを既読にする
     * @param {既読にするID} id
     */
    infoRead(id) {
      let reades = (this.$cookies.get("infoReads") ?? "").split(",");
      if (reades.indexOf(id) == -1) {
        reades.push(id);
      }
      this.$cookies.set("infoReads", reades, "5y");
    },
    /**
     * Base64のデータをblobに変換
     * @param {base64データ} data64
     * @param {データ・タイプ} mime_ctype
     * @returns
     */
    toBlob(data64, mime_ctype) {
      var bin = atob(data64.replace(/^.*,/, ""));
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      // Blobを作成
      try {
        var blob = new Blob([buffer.buffer], {
          type: mime_ctype,
        });
      } catch (e) {
        return false;
      }
      return blob;
    },
  },
};
